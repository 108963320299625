import React, { useEffect } from 'react';
import { Paper} from '@material-ui/core';
import { LessorProfile } from '../components';
import { checkPermission } from '../../utils_v2';
import { getLocalStorageInfo } from '../../utils_v2';
import ConfigSideNav from '../components/ConfigSideNav';
import { browserHistory } from 'react-router';
import DeploymentMessage from '../../../shared/components/DeploymentMessage';
const LessorConfig = ({ }) => {
    useEffect(() => {
        if (checkPermission('console', 'lessor_configuration', 'R')) {
            return
        } else if (checkPermission('console', 'distribution_emails', 'R')) {
            return browserHistory.push('/configure/distribution')
        } else if (checkPermission('console', 'introduction', 'R')) {
            return browserHistory.push('/configure/introduction')
        } else if (checkPermission('console', 'owner_details', 'R')) {
            return browserHistory.push('/configure/owner-lessor')
        } else if (checkPermission('console', 'banking_details', 'R')) {
            return browserHistory.push('/configure/banking-details')
        } else if (checkPermission('console', 'add_lessee', 'R')) {
            return browserHistory.push('/configure/favourite-lessee')
        } else if (checkPermission('console', '3rd_party_integration', 'R')) {
            return browserHistory.push('/configure/third-party-integration')
        } else {
            return 
        }
    }, [])
    
    return (
        <section className='config-container' >
        <DeploymentMessage />
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 90}px` }}>
                <ConfigSideNav />
                {checkPermission('console', 'lessor_configuration', 'R') ?
                    <div className="tech-specs-content">
                        <Paper style={{ marginBottom: '15px', padding: '15px' }}>
                            <LessorProfile />
                        </Paper>
                    </div>
                    : null
                }
            </Paper>
        </section>
    )
}
export default LessorConfig;