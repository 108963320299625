import React, { useEffect, useState } from 'react'
import { Paper } from '@material-ui/core';
import { globalGetService } from '../../../globalServices';
import moment from 'moment';
import { checkApiStatus } from '../../utils_v2';
import { STableLoader } from '../../shared_elements';

export default function WhatsNew() {

    const [featureList, setFeatureList] = useState([])
    const [isLoading, setPageLoader] = useState(false)
    useEffect(() => {
        getFeatureList()
    }, [])
    const getFeatureList = () => {
        setPageLoader(true)
        globalGetService('/api/features/', { application: 1 })
            .then(response => {
                setPageLoader(false)
                if (checkApiStatus(response)) {
                    setFeatureList(response.data.data)
                }
            })
    }
    console.log('featureList', featureList);
    return (
        <section className='whats-new-container'>
            <div className='whats-new-header'>What's New</div>
            <div className='whats-new-body'>
                {featureList.map((feature, index) => (
                    <WhatsNewSection
                        feature={feature}
                    />
                ))}

            </div>
            {
                isLoading ?
                    <STableLoader count={6} />
                    : null
            }
        </section>
    )
}
const WhatsNewSection = ({ feature }) => {
    return (
        <Paper className='feature-card'>
            <p className="feature-date" style={{ "font-size": "10px" }}>{feature.release_date ? moment(feature.release_date).format('MMM DD, YYYY') : '-'}</p>
            <h2 className="feature-title-wn" style={{ "font-size": "16px" }}>{feature.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: feature.description ? feature.description : '' }}></div>
        </Paper>
    )
}