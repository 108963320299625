import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { RoleHeader, Role, DeleteRole, AddEditRole } from '../components'
import { globalGetService, globalDeleteService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { downloadFileType } from '../../../utils'
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
import { Pagination, FilterComponent, STableLoader, PageLoader, EmptyCollection } from '../../shared_elements';
import { SettingsNavWrapper } from '../../elements'
import { Table, TableBody, Paper, Grid, Button } from '@material-ui/core';
import { rolesFilters } from '../';
const Roles = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [deleteRole, setDeleteRole] = useState({modal:false, role:null, item:null})
    const [addEditRole, setAddEditRole] = useState({modal:false, mode:'', app:null, item:{id:'', name:'', application:[]}})
    const [rolesInfo,setRolesInfo] = useState({list:[], pagination:{}});
    const [roleUsers, setRoleUsers] = useState([]);
    const [allApplications, setAllApplications] = useState([]);
    const [alterOptions, setAlterOptions] = useState([]);
    const [roleProfilesInfo,setRoleProfilesInfo] = useState([]);
    const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
    useEffect(() => {
        getRoles({...filter, per_page:50}, 'skeletonLoader');
        getAllApplications();
        getRoleProfiles()
    },[]);
    const getAllApplications = () => {
        globalGetService(`console/application_dropdown/`,{all:true})
        .then(response => {
            if(checkApiStatus(response)){
                setAllApplications(response.data.data)
            }
        })
    }
    const getRoleProfiles = (query={}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`console/group_profile/`,{...query, all:true})
        .then(response => {
            if(checkApiStatus(response)){
                setRoleProfilesInfo(response.data.data);
                delete query.sort
                delete query.sort_by
                setFilter(query);
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        });
    }
    const getRoles = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`console/roles/`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setRolesInfo(response.data.data);
                delete query.page
                delete query.per_page
                delete query.sort
                delete query.sort_by
                setFilter(query);
            }else{

            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        });
    }
    console.log('first',roleProfilesInfo)
    const getRole = (role, mode) => {
        setLoading(true)
        globalGetService(`console/role/${role.application.id}/${role.id}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setAddEditRole({modal:true, mode:mode, app: role.application, item:response.data.data});
                if(mode === 'view'){
                    setRoleUsers(role.users);
                }
                setLoading(false)
            }else{

            }
        })
    }
    const getAlternateRoles = (item) => {
        setDeleteRole({modal:true,item});
        if(item.users.length){
            globalGetService('console/roles-dropdown/')
            .then(response => {
                if(checkApiStatus(response)){
                    let options = response.data.data.filter(app => (app.application.id === item.application.id && app.name !== item.name))
                    setAlterOptions(options);
                }else{

                }
            });
        }
    }
    const onDeleteRole = () => {
        let payload = {}
        if(deleteRole.item.users.length){
            if(!deleteRole.role){
                enqueueSnackbar('Please select the Alternate Role', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                return
            }else{
                payload = {alternate_group_id:deleteRole.role.id}
            }
        }
        globalDeleteService(`console/role/${deleteRole.item.application.id}/${deleteRole.item.id}/`,payload)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setDeleteRole({modal:false, role:null, item:null});
                getRoles({...filter, per_page:50}, 'pageLoader');
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
        })
    }
    let filterOptions = Object.assign({}, rolesFilters);
    filterOptions = {
        ...filterOptions,
        application_id:{
            ...filterOptions.application_id,
            options:allApplications
        },
        group_profile_id:{
            ...filterOptions.group_profile_id,
            options:roleProfilesInfo
        }

    }
    const createSortHandler = (key) => {
        let sortQuery = { sort: key };
        if (sortInfo.sort === key) {
            sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
        } else {
            sortQuery = { ...sortQuery, sort_by: 'asc' }
        }
        setSortInfo(sortQuery)
        getRoles({ ...filter, ...sortQuery, page: 1, per_page: rolesInfo?.pagination?.per_page }, 'pageLoader');
    }
    return(
        <section className='roles-management' style={{margin:'24px 0 0px 276px'}}>
            <SettingsNavWrapper />
            <div>
                { skeletonLoader ? <STableLoader count={4} />:
                    <>
                        <Paper square style={{padding:'5px 10px'}}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={8}>
                                    <FilterComponent 
                                        filter={filter} 
                                        filterMenu={filterOptions}
                                        getResponseBack={(applyFilter) => getRoles({...applyFilter, page:1, per_page:rolesInfo.pagination.per_page}, 'pageLoader')}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <ul className='list-inline' style={{float:'right'}}>
                                        { checkPermission('settings', 'custom_roles', 'C') ?
                                            <li className='list-inline-item'>
                                                <Button onClick={()=>setAddEditRole({modal:true, app:null, item:null})} color='primary' size='small' variant='contained'>Add Role</Button>
                                            </li>:null
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper>
                            <div style={{height: !rolesInfo.list.length ? 'auto':`${window.innerHeight-180}px`,overflow:'auto'}}>
                                <Table className='mui-table-format hovered-table' stickyHeader>
                                    <RoleHeader createSortHandler={createSortHandler} sortInfo={sortInfo} />
                                    <TableBody>
                                        {rolesInfo.list.map((item,index) =>
                                            <Role 
                                                item={item}
                                                key={index}
                                                rolesInfo={rolesInfo}
                                                onView={() => getRole(item, 'view')}
                                                onEdit={() => getRole(item, 'edit')}
                                                onDelete={() => getAlternateRoles(item)}
                                            />
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                            <Pagination 
                                pagination={rolesInfo.pagination}
                                onChangePage={(event, newPage) => getRoles({page:newPage+1, per_page: rolesInfo.pagination.per_page, ...filter, ...sortInfo}, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getRoles({page:1, per_page: event.target.value, ...filter, ...sortInfo}, 'pageLoader')}
                            />
                            {!rolesInfo.list.length ? <div style={{textAlign:'center'}}><EmptyCollection title={'No records found'} /></div>:null}
                        </Paper>
                    </>
                }
            </div>
            { addEditRole.modal ? 
                <AddEditRole 
                    addEditRole={addEditRole}
                    toggleModalFn={()=> {setAddEditRole({modal:false, mode:'', app:null, item:null}); setRoleUsers([])}}
                    getResponseBack={() => getRoles({...filter, per_page:rolesInfo.pagination.per_page}, 'pageLoader')}
                    roleUsers={roleUsers}
                />:null
            }
            { deleteRole.modal ?
                <DeleteRole 
                    alterOptions={alterOptions}
                    deleteRole={deleteRole}
                    modal={deleteRole.modal}
                    onChangeRole={(value) => setDeleteRole({...deleteRole, role:value})}
                    onDeleteRole={onDeleteRole}
                    toggleModalFn={() => setDeleteRole({modal:false, item:null})}
                    isLoading={isLoading}
                />:null
            }
            { isLoading ? <PageLoader />:null }
        </section>
    )
}
export default RoleBasedAccess(Roles,['settings','custom_roles','R']);