import Roles from './roles/pages/Roles';
import PendingUsers from './users/pages/PendingUsers';
import ActiveUsers from './users/pages/ActiveUsers';
import RoleProfiles from './roles/pages/RoleProfiles';
import UserDetail from './users/pages/UserDetail';
import MyProfile from './users/pages/MyProfile';
import ChangePassword from './users/pages/ChangePassword';
import LessorConfig from './config/pages/LessorConfig';
import MajorAssemblies from './fleet/pages/MajorAssemblies';
import ArchivedAssets from './fleet/pages/ArchivedAssets';
import ActiveAssets from './fleet/pages/ActiveAssets';
import Inventories from './fleet/pages/Inventories';
import BasicInfo from './techspecs/pages/BasicInfo';
import Utilizations from './techspecs/pages/Utilizations';
import LLPs from './techspecs/pages/LLPs';
import MovementTracker from './techspecs/pages/MovementTracker';
import ShopVisits from './techspecs/pages/ShopVisits';
import AdditionalRemarks from './techspecs/pages/AdditionalRemarks';
import IncidentsAndAog from './techspecs/pages/IncidentsAndAog';
import StcMods from './techspecs/pages/StcMods';
import Avionics from './techspecs/pages/Avionics';
import Interior from './techspecs/pages/Interior';
import TermsConditions from './auth/pages/TermsConditions'
import requireAuth from '../utils/requireAuth'
import RoleBasedAccess from '../utils/RoleBasedAccess'
import DataPolicy from './auth/pages/DataPolicy';
import PasswordAuth from './auth/pages/PasswordAuth';
import LoginForm from './auth/pages/LoginForm';
import ForgotPwd from './auth/pages/ForgotPwd';
import LoggedUserRed from '../utils/LoggedUserRed';
import WhatsNew from './fleet/pages/WhatsNew';
import FAQs from './fleet/pages/FAQs';
import Notifications from './fleet/pages/Notifications';
import AssetTimeline from './fleet/pages/AssetTimeline';
import Reports from './reports/pages/Report';
import { configRoutes } from './config/configRoutes'
import TechSpecsRedirection from './techspecs/TechSpecsRedirection'
export const consoleRoutes = [
    {
        path:'/whats-new',
        component:requireAuth(WhatsNew),
        key:'WhatsNew'
    },
    {
        path:'/faqs',
        component:requireAuth(FAQs),
        key:'FAQs'
    },
    {
        path:'/notifications',
        component:requireAuth(Notifications),
        key:'notifications'
    },
    {
        path:'/forgot-password',
        component:LoggedUserRed(ForgotPwd),
        key:'ForgotPwd'
    },
    {
        path:'/login',
        component:LoggedUserRed(LoginForm),
        key:'LoginForm'
    },
    { 
        path:'/password/:type/:token',
        component:LoggedUserRed(PasswordAuth),
        key:'PasswordAuth'
    },
    {
        path:'/data-policy',
        component:DataPolicy,
        key:'DataPolicy'
    },
    {
        path:'/termscondition',
        component:TermsConditions,
        key:'TermsConditions'
    },
    {
        path:'/settings/roles',
        component:requireAuth(Roles),
        key:'Roles'
    },
    {
        path:'/settings/profile',
        component:requireAuth(RoleProfiles),
        key:'RoleProfiles'
    },
    {
        path:'/users/invites',
        component:requireAuth(RoleBasedAccess(PendingUsers,['settings','manage_user','R'])),
        key:'PendingUsers'
    },
    {
        path:'/users/manage',
        component:requireAuth(RoleBasedAccess(ActiveUsers,['settings','manage_user','R'])),
        key:'ActiveUsers'
    },
    {
        path:'/users/manage/:slug',
        component:requireAuth(RoleBasedAccess(UserDetail,['settings','manage_user','R'])),
        key:'UserDetail'
    },
    {
        path:'/user-profile',
        component:requireAuth(MyProfile),
        key:'MyProfile'
    },
    {
        path:'/change-password',
        component:requireAuth(ChangePassword),
        key:'ChangePassword'
    },
    {
        path:'/configure',
        component:requireAuth(LessorConfig),
        key:'LessorConfig'
    },
    {
        path:'/major-assemblies',
        component:requireAuth(RoleBasedAccess(MajorAssemblies,['technical_specs', 'major_assemblies','R'])),
        key:'MajorAssemblies'
    },
    {
        path:'/archived-assets',
        component:requireAuth(RoleBasedAccess(ArchivedAssets,['technical_specs','archived_assets','R'])),
        key:'ArchivedAssets'
    },
    {
        path:'/assets-listing',
        component:requireAuth(ActiveAssets),
        key:'ActiveAssets'
    },
    {
        path:'/inventories',
        component:requireAuth(RoleBasedAccess(Inventories,['technical_specs','inventory','R'])),
        key:'Inventories'
    },
    {
        path:'/technical-specification/:type/:aircraft_slug/utilization',
        component:requireAuth(RoleBasedAccess(Utilizations,['technical_specs', 'engine','R'])),
        key:'Utilizations'
    },
    {
        path:'/technical-specification/:type/:aircraft_slug/basic-details',
        component:requireAuth(RoleBasedAccess(BasicInfo,['technical_specs', 'engine','R'])),
        key:'BasicInfo'
    },
    {
        path:'/technical-specification/:type/:aircraft_slug',
        component:requireAuth(TechSpecsRedirection),
        key:'TechSpecsRedirection'
    },
    {
        path:'/technical-specification/:type/:aircraft_slug/shop-visits',
        component:requireAuth(RoleBasedAccess(ShopVisits,['technical_specs', 'shop_visit','R'])),
        key:'ShopVisits'
    },
    {
        path:'/technical-specification/:type/:aircraft_slug/avionics',
        component:requireAuth(Avionics),
        key:'Avionics'
    },
    {
        path:'/technical-specification/:type/:aircraft_slug/interior',
        component:requireAuth(Interior),
        key:'Interior'
    },
    {
        path:'/technical-specification/:type/:aircraft_slug/stc-mods',
        component:requireAuth(StcMods),
        key:'StcMods'
    },
    {
        path:'/technical-specification/:type/:aircraft_slug/incident-aog',
        component:requireAuth(IncidentsAndAog),
        key:'IncidentsAndAog'
    },
    {
        path:'/technical-specification/:type/:aircraft_slug/llps',
        component:requireAuth(RoleBasedAccess(LLPs,['technical_specs', 'engine','R'])),
        key:'LLPs'
    },
    {
        path:'/technical-specification/:type/:aircraft_slug/movement-tracker',
        component:requireAuth(MovementTracker),
        key:'MovementTracker'
    },
    {
        path:'/technical-specification/:type/:aircraft_slug/additional-remarks',
        component:requireAuth(RoleBasedAccess(AdditionalRemarks,['technical_specs', 'additional_remarks','R'])),
        key:'AdditionalRemarks'
    },
    {
        path:'/technical-specification/:type/:aircraft_slug/timeline',
        component:requireAuth(RoleBasedAccess(AssetTimeline,['technical_specs', 'asset_timeline','R'])),
        key:'AssetTimeline'
    },
    {
        path:'/export/reports',
        component:requireAuth(Reports),
        key:'Reports'
    },
    ...configRoutes
]
