import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
const PopupVideo = ({videoModal, toggle}) => {
  return(
    <Dialog onClose={toggle} aria-labelledby="customized-dialog-title" open={videoModal}>
      <DialogTitle id="customized-dialog-title" onClose={toggle}>Watch Now</DialogTitle>
      <DialogContent dividers>
        <h4>Video coming soon...</h4>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={toggle} color="primary" size='small'> Close </Button>
      </DialogActions>
    </Dialog>
  )
}
export default PopupVideo;