import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress } from '@material-ui/core';
// import { globalPutService, globalPostService } from '../../../utils_v2/globalApiServices';
import { regexConstants } from '../../../constants/regEx';
import { checkApiStatus } from '../../utils_v2';
import { globalPostService, globalPutService } from '../../../globalServices';

const ContactCRU = ({params, addEditContact, toggleModalFn, getResponseBack}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false)
  const [contact, setContact] = useState(addEditContact.contact?addEditContact.contact:{});
  const [error, setError] = useState({});
  const onFieldChange = (e, keyParam, value) => {
    setContact({
      ...contact,
      [keyParam]: value
    });
  }
  const resetErrorKey = (key) => {
    setError({
      ...error,
      [key]: ''
    });
  }
  const onAddEditContact = () => {
    let validationInputs={
      full_name:contact?.full_name?.trim()?.length ? '':'Please enter valid name.',
      email: contact?.email?.trim()?.length ? regexConstants.email.test(contact.email) ? '' : 'Please enter valid Email Address' : 'Please enter Valid Email Address',
      phone_number: contact?.phone_number?.trim()?.length ? regexConstants.phoneNumber.test(contact.phone_number) ? '' : 'Please enter valid Phone number' : '',
      fax: contact?.fax?.trim()?.length ? regexConstants.fax.test(contact.fax) ? '' : 'Please enter valid Fax number' : '',
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      setLoading(true);
      if(addEditContact.contact){
        globalPutService(`console/airline-contact/${contact?.id}/`, contact)
        .then(response => {
          setLoading(false);
          if(checkApiStatus(response)){
            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            toggleModalFn(); getResponseBack();
          }else{
            enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
          }
        })
      }else{
        globalPostService(`console/airline-contact/`, contact)
        .then(response => {
          setLoading(false);
          if(checkApiStatus(response)){
            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            toggleModalFn(); getResponseBack();
          }else{
            enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
          }
        })
      }
    }else{
      setError(validationInputs);
    }
  }
  return(
    <Dialog
      open={addEditContact.modal}
      className='console-management-modal'
    >
      <DialogTitle id="scroll-dialog-title">
        {addEditContact.mode} Contact
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              required
              id="full_name"
              label="Name"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 100}}
              value={contact.full_name ? contact.full_name:''}
              onChange={(e, value) => onFieldChange(e, 'full_name', e.target.value)}
              error={error.full_name ? true:false }
              helperText={error.full_name ? error.full_name:''}
              InputLabelProps={{shrink: true}}
              onFocus={(e) => resetErrorKey('full_name')}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="email"
              inputProps={{ maxLength:100 }}
              label="Email"
              fullWidth
              margin="normal"
              value={contact.email ? contact.email:''}
              onChange={(e, value) => onFieldChange(e, 'email', e.target.value)}
              error={error.email ? true:false }
              helperText={error.email ? error.email:''}
              InputLabelProps={{shrink: true}}
              onFocus={(e) => resetErrorKey('email')}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="title"
              label="Designation"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 100}}
              value={contact.title ? contact.title:''}
              onChange={(e, value) => onFieldChange(e, 'title', e.target.value)}
              error={error.title ? true:false }
              helperText={error.title ? error.title:''}
              InputLabelProps={{shrink: true}}
              onFocus={(e) => resetErrorKey('title')}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="phone_number"
              label="Phone Number"
              fullWidth
              margin="normal"
              value={contact.phone_number ? contact.phone_number:''}
              onChange={(e) => {const phoneNumber = e.target.value;if ( phoneNumber === '' || regexConstants.numberWithSpecChar.test(phoneNumber)) {onFieldChange(e, 'phone_number', phoneNumber);}}}
              error={error.phone_number ? true:false }
              helperText={error.phone_number ? error.phone_number:''}
              inputProps={{ maxLength:15 }}
              InputLabelProps={{shrink: true}}
              onFocus={(e) => resetErrorKey('phone_number')}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="fax"
              label="Fax"
              fullWidth
              margin="normal"
              value={contact.fax ? contact.fax:''}
              onChange={(e, value) => onFieldChange(e, 'fax', e.target.value)}
              error={error.fax ? true:false }
              helperText={error.fax ? error.fax:''}
              inputProps={{ maxLength:15 }}
              InputLabelProps={{shrink: true}}
              onFocus={(e) => resetErrorKey('fax')}
              variant='outlined'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
        <Button disabled={isLoading} onClick={onAddEditContact} variant="contained" color="primary" size="small">
          {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withRouter(ContactCRU);