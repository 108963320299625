import React, { useState } from "react";
import { Paper, Grid, Container } from "@material-ui/core";
import AppSlider from '../components/AppSlider';
import ForgotPwdForm from "../components/ForgotPwdForm";
import { isMobile } from 'react-device-detect';
import {PageLoader} from "../../shared_elements";

const ForgotPwd = ({ }) => {
    const [isLoading, setLoading] = useState(false);
    return (
        <section className="login-auth-section" style={{height: `${window.innerHeight}px`}}>
            <Container maxWidth='lg' className="centre-blk">
                <Paper className="password-section">
                    <Grid container spacing={0} >
                        <Grid item md={6} style={{position:'relative'}}>
                            <ForgotPwdForm 
                             isLoading={isLoading}
                             getLoaderResponse={(flag) => setLoading(flag)}
                            />
                        </Grid>
                        {<Grid item md={6}>
                            <div className="img-slider-wrapper">
                                <AppSlider />
                            </div>
                        </Grid>}
                    </Grid>
                </Paper>
            </Container>
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}
export default ForgotPwd;