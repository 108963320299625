import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { Chip, Grid, TextField, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DeletePopUp, STableLoader, PageLoader, EmptyCollection, AutocompleteCreatable } from '../../shared_elements'
import { globalGetService, globalPostService, globalPutService, globalDeleteService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { regexConstants } from '../../../constants/regEx';
import ConfigSideNav from './ConfigSideNav';
const AddDistributionEmail = ({ distributionEmailsInfo, addEditEmail, toggleModalFn, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [distributionEmail, setDistributionEmail] = useState(addEditEmail.data)
    const [lessee, setLessee] = useState([])
    useEffect(() => {
        getLessee({}, 'skeletonLoader')
    }, [])
    const getLessee = () => {
        setLoading(true);
        globalGetService('console/lessees/', {})
            .then(response => {
                if (checkApiStatus(response)) {
                    let lesseeIds = distributionEmailsInfo.map(item => item.lessee.id);
                    setLessee(response.data.data.lessees.filter(lessee => !lesseeIds.includes(lessee.id)))
                    setLoading(false)
                }
            })
    }
    const onFieldChange = (key, value) => {
        if (key === 'emails') {
            const arrayUniqueByKey = [...new Map(value.map(item => [item['inputValue'], item])).values()];
            setDistributionEmail({ ...distributionEmail, [key]: arrayUniqueByKey })
        } else {
            setDistributionEmail({ ...distributionEmail, [key]: value })
        }
        setError({ ...error, [key]: '' });
    }
    const onAddEditEmail = () => {
        let validationInputs = {
            lessee: distributionEmail?.lessee?.id ? '' : 'Please select Lessee',
            emails: distributionEmail?.emails?.length ? '' : 'Please enter Email(s)'
        }
        if (distributionEmail?.emails.length) {
            let errorEmails = [];
            distributionEmail.emails.map(item => {
                if (!regexConstants.email.test(item.inputValue)) {
                    errorEmails.push(item.inputValue);
                }
            });
            if (errorEmails.length) {
                validationInputs = {
                    ...validationInputs,
                    emails: `Please enter valid emails for "${errorEmails.join(',')}"`
                }
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            let payload = Object.assign({}, distributionEmail)
            payload = {
                ...payload,
                lessee: payload?.lessee?.id,
                emails: payload?.emails.map(item => item.inputValue)
            }
            setLoading(true);
            if (distributionEmail.id) {
                globalPutService(`console/distribution-emails/${distributionEmail.id}/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            toggleModalFn(); getResponseBack();
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        }
                        setLoading(false);
                    })
            } else {
                globalPostService(`console/distribution-emails/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            toggleModalFn(); getResponseBack();
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        }
                        setLoading(false);
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    return (
        <Dialog
            open={addEditEmail.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {distributionEmail?.id ? 'Edit ' : 'Add '} Distribution Email(s)
            </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{ width: '380px' }}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <Autocomplete
                                options={lessee}
                                getOptionLabel={option => option.name}
                                id="lessee"
                                value={distributionEmail?.lessee}
                                onChange={(e, value) => onFieldChange('lessee', value)}
                                renderInput={params => <TextField required error={error.lessee} helperText={error?.lessee || ''} {...params} label="Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <AutocompleteCreatable
                                options={[]}
                                paramsKey='emails'
                                optionKey='label'
                                label='Email(s)'
                                value={distributionEmail?.emails || []}
                                onFieldChange={(e, paramsKey, newValue) => onFieldChange('emails', newValue)}
                                required={true}
                                multiple={true}
                                error={error.emails}
                                helperText={error?.emails || ''}
                            />
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditEmail} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const DistributionEmails = ({ }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [addEditEmail, setAddEditEmail] = useState({ modal: false, mode: '', data: null })
    const [deleteEmail, setDeleteEmail] = useState({ modal: false, data: null })
    const [distributionEmailsInfo, setDistributionEmailsInfo] = useState([])
    useEffect(() => {
        getDistributionEmails({}, 'skeletonLoader')
    }, [])
    const onDeleteEmail = () => {
        setLoading(true);
        globalDeleteService(`console/distribution-emails/${deleteEmail.data.id}/`, {})
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeleteEmail({ modal: false, data: null })
                    getDistributionEmails();
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
                setLoading(false);
            })
    }
    const getDistributionEmails = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/distribution-emails/`, {})
            .then(response => {
                if (checkApiStatus(response)) {
                    setDistributionEmailsInfo(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false);
            })
    }
    return (
        <section className="config-container">
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 90}px` }}>
                <ConfigSideNav />
                <div className="tech-specs-content">
                    {skeletonLoader ? <STableLoader count={3} /> :
                        <>
                            <div style={{ textAlign: 'right' }}>
                                {checkPermission('console', 'distribution_emails', 'C') ?
                                    <Button style={{ marginBottom: '15px' }} onClick={() => setAddEditEmail({ modal: true, mode: 'add', data: null })} color='primary' size='small' variant='contained'>Add Distribution Email(s)</Button> : null
                                }
                            </div>
                            <Paper style={{height:`${window.innerHeight - 145}px`, overflow:'scroll'}}>
                                <Table className='mui-table-format'>
                                    <TableHead style={{position:'sticky', top:'0'}}>
                                        <TableRow>
                                            <TableCell>Lessee</TableCell>
                                            <TableCell>Email(s)</TableCell>
                                            <TableCell align='right'>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {distributionEmailsInfo?.map((item, index) =>
                                            <TableRow key={index}>
                                                <TableCell>{item.lessee.name}</TableCell>
                                                <TableCell>
                                                    {item.emails.split(",").map((email) =>
                                                        <Chip label={email} size='small' />
                                                    )}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {checkPermission('console', 'distribution_emails', 'U') ?
                                                        <EditIcon style={{cursor:'pointer'}} onClick={() => setAddEditEmail({ modal: true, mode: 'edit', data: { ...item, emails: item.emails.split(',').map(email => { return { inputValue: email, label: `Add ${email}` } }) } })} color='primary' fontSize='small' /> : null
                                                    }
                                                    {checkPermission('console', 'distribution_emails', 'D') ?
                                                        <DeleteOutlineIcon style={{cursor:'pointer'}} onClick={() => setDeleteEmail({ modal: true, data: item })} color='secondary' fontSize='small' /> : null
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                <Paper>
                                {!distributionEmailsInfo ? <div style={{ textAlign: 'center' }}><EmptyCollection title={'No records found'} /></div> : null}
                                </Paper>
                            </Paper>
                        </>
                    }
                </div>
            </Paper>
            {addEditEmail.modal ?
                <AddDistributionEmail
                    addEditEmail={addEditEmail}
                    toggleModalFn={() => setAddEditEmail({ modal: false, data: null })}
                    getResponseBack={() => getDistributionEmails()}
                    distributionEmailsInfo={distributionEmailsInfo}
                /> : null
            }
            {deleteEmail.modal ?
                <DeletePopUp
                    modal={deleteEmail.modal}
                    title='Delete Distribution Email(s)'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteEmail({ modal: false, data: null })}
                    deleteRecordFn={onDeleteEmail}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}
export default DistributionEmails;