import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import JSONViewer from 'react-json-viewer';
import LabelValueCard from './LabelValueCard';
import moment from 'moment';
import { backendDateFormat, displayDateTimeFormatShort } from '../../constants';
import { revisionInItem } from '../../shared/components';
const RevisionAddCard = ({item}) => {
  console.log('item',item.content_type)
  return(
    <Fragment>
      <Grid container spacing={3}>
        <LabelValueCard md={6} label='Created at' value={ moment(item.timestamp).format(displayDateTimeFormatShort)} />
        <LabelValueCard md={6} label='Created by' value={item.user && item.user.name ? item.user.name:'--'} />
        {
          item?.content_type && item?.object_repr ?
            <Grid md={6} className={'label-value-card'} style={{margin:'0px 0px 20px 13px'}}>
              <p>  Revision In</p>
              <h6>{`${revisionInItem[item?.content_type]} (${item?.object_repr})`}</h6>
            </Grid>
            : null
        }
      </Grid>
      {item?.content_type!=='amutilization'?
      <div className="edit-detail-table">
        <JSONViewer json={item.changes}></JSONViewer>
      </div>:null}
    </Fragment>
  )
}
export default RevisionAddCard;
