import React, { useState } from 'react'
import { TextField, Button, Tooltip, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress, Grid, InputAdornment } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { globalPostService, globalPutService } from '../../globalServices';
import { Info } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { checkApiStatus } from '../../utils';
import PageLoader from './PageLoader';
import { Close } from '@material-ui/icons'

export default function AddNewLessee({ addEditLessee, toggleModalFn, getResponseBack }) {
    const [lessee, setLessee] = useState(addEditLessee?.data)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [error, setError] = useState({})
    const [logoLoader, setLogoLoader] = useState(false)
    const [isLoading, setLoader] = useState(false)
    const [lesseeImg, setLesseeImg] = useState(addEditLessee?.mode =='edit' && addEditLessee?.data?.logo ? {url:addEditLessee?.data?.logo, path: addEditLessee?.data?.path}: null)
    const saveNewLessee = () => {
        let validateNewInput = {}
        validateNewInput = {
            ...validateNewInput,
            name: lessee?.name ? '' : 'Please enter Lessee Name'
        }
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
            let payload = Object.assign({}, lessee)
            payload = {
                ...payload,
                logo: lesseeImg?.path ? lesseeImg?.path: null,
                contact_address: payload?.contact_address? payload?.contact_address: null,
                country: payload?.country? payload?.country: null
            }
            setLoader(true)
            if(payload?.slug){
                globalPutService(`console/lessor-lessees/${payload?.slug}/`, payload)
                .then(response => {
                    setLoader(false)
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                        toggleModalFn()
                        getResponseBack()
                    }
                })
            }
            else{
                globalPostService(`console/lessor-lessees/`, payload)
                .then(response => {
                    setLoader(false)
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                        toggleModalFn()
                        getResponseBack()
                    }
                })
            }
        }
        else {
            setError(validateNewInput)
        }
    }
    const onFieldChange = (key, value) => {
        if (key == 'files') {
            let formData = new FormData()
            formData.append('file', value)
            setLogoLoader(true)
            globalPostService(`/console/upload-lessee-logo/`, formData)
                .then(response => {
                    setLogoLoader(false)
                    if (response.data.statusCode == 200) {
                        setLesseeImg(response.data.data)
                    }
                })
        }
        else {
            setLessee({
                ...lessee,
                [key]: value
            })
        }
    }
    return (
        <div>
            {
                isLoading? <PageLoader />: null
            }
            <Dialog
                open={addEditLessee?.modal}
                onClose={toggleModalFn}
                className='console-management-modal add-edit-role-modal add-edit-lessee'
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "750px",
                        },
                    },
                }}
            >
                <DialogTitle id="scroll-dialog-title">
                    {addEditLessee?.mode === 'edit' ? 'Edit': 'Add New'} Lessee
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container spacing={1}>
                        <Grid item md={6}>
                            <TextField
                                required
                                id='name'
                                value={lessee?.name || ''}
                                fullWidth
                                placeholder='Enter Name'
                                margin="normal"
                                label='Name'
                                inputProps={{ maxLength: 255 }}
                                onChange={(e) => onFieldChange('name', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                                error={error.name ? true : false}
                                helperText={error?.name || ''}
                                onFocus={() => setError({ ...error, name: '' })}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                id='country'
                                value={lessee?.country || ''}
                                fullWidth
                                placeholder='Enter County name'
                                margin="normal"
                                inputProps={{ maxLength: 255 }}
                                label='Country'
                                onChange={(e) => onFieldChange('country', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={12} style={{marginBottom:"4px"}}>
                            <TextField
                                className='contact-add-edit-lessee'
                                id='contact_address'
                                value={lessee?.contact_address || ''}
                                fullWidth
                                multiline
                                rows={2}
                                placeholder='Enter Contact Address'
                                margin="normal"
                                label='Contact Address'
                                onChange={(e) => onFieldChange('contact_address', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <h3 className='font-implex' style={{ display: "flex", alignItems: 'center', paddingLeft:'13px', fontSize:"11px", }}> {lesseeImg?.url ? 'Change Logo' : 'Upload Logo'}
                                <Tooltip title='Please upload .jpg/.jpeg or .png file only (Max upto 5MB)' arrow>
                                    <Info style={{ fontSize: "17px", marginLeft: '5px' }} />
                                </Tooltip>
                            </h3>
                            <DropzoneArea
                                acceptedFiles={['.png, .jpg, .jpeg']}
                                filesLimit={1}
                                maxFileSize={5242880}
                                showPreviewsInDropzone={false}
                                useChipsForPreview={false}
                                showPreviews={false}
                                dropzoneText={<p style={{ fontSize: '13px' }}>Upload Logo<br /> OR <br /> Click Here to upload<br /> </p>}
                                dropzoneClass="drag-drop-cnt"
                                maxWidth="sm"
                                showAlerts={['info', 'error']}
                                alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                clearOnUnmount={true}
                                onDrop={(files) => onFieldChange('files', files[0])}
                            />
                        </Grid>
                        <Grid item md={6}>
                            {lesseeImg && !logoLoader ? <h3 className='font-implex' style={{ fontSize:"11px", fontWeight:'800' }}> Preview </h3> : null}
                            {
                                logoLoader ?
                                    <CircularProgress size={100} style={{ margin: '41px 0px 0px 40px' }} /> :
                                    lesseeImg ?
                                        <div>
                                             <Close style={{float:'right', cursor:'pointer', background:"#fafafa"}} onClick={()=> setLesseeImg(null)}/>
                                            <img style={{ height: '110px', marginTop: '3px', maxWidth:'320px' }} src={lesseeImg?.url} alt='Logo Preview' />
                                        </div>
                                        : null

                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                    <Button onClick={saveNewLessee} color='primary' size='small' variant='contained'>Save
                        {/* {isLoading ? <CircularProgress size={24} />:'Save'} */}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
