import React, { useState } from 'react';
import { Button, Grid, IconButton, InputAdornment, TextField, Modal, Backdrop, Fade } from "@material-ui/core";
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockIcon from '@material-ui/icons/Lock';
import SolutionLogoConst from './SolutionLogoCont';
import SelectLessor from './SelectLessor';
import LoginAuth from './LoginAuth';
import { checkApiStatus } from "../../utils_v2";
import { globalPostService } from "../../../globalServices";
import { regexConstants } from '../../../constants/regEx'
import enableAssetListing from '../apiService';
const Login = ({match, getLoaderResponse, isLoading}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [userInfo, setUserInfo] = useState({ email: '', password: '' });
    const [pwdVisibility, setPwdVisibility] = useState({ password: false })
    const [error, setError] = useState({});
    const [otp, setOtp] = useState(false);
    const [open, setOpen] = useState(false);
    const [userResponse, setUserResponse] = useState(null)
    const onLoginSubmit = (e) => {
        e.preventDefault();
        let validationInputs = {
            email: userInfo?.email?.trim()?.length ? regexConstants.email.test(userInfo.email) ? '' : 'Please enter valid Email Address' : 'Please enter valid registered email address',
            password: userInfo?.password?.trim()?.length ? '' : 'Please enter valid Password'
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            getLoaderResponse(true)
            globalPostService('api/login/', userInfo)
                .then((response) => {
                    getLoaderResponse(false)
                    if (checkApiStatus(response) && response.data.statusCode !== 3001) {
                        setUserResponse(response.data.data);
                        if (response?.data?.data?.user.two_f_auth_enabled) {
                            setOtp(true);
                        } else {
                            if (response?.data?.data?.lessor_list?.length > 1) {
                                setOpen(true)
                            } else {
                                if (response.data.data.lessor_list.length == 1) {
                                    enableAssetListing(response.data.data.lessor_list[0].id, response.data.data.access,getLoaderResponse)
                                }
                            }
                        }
                    }else{
                        // enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });

                    }
                })
        } else {
            setError(validationInputs)
        }
    }
    const onOtpVerify = (e) => {
        if (userResponse?.lessor_list?.length > 1) {
            setOpen(true)
        } else {
            if (userResponse?.lessor_list?.length == 1) {
                enableAssetListing(userResponse.lessor_list[0].id, userResponse.access, getLoaderResponse)
                getLoaderResponse(true)
            } else {
                getLoaderResponse(false)
            }
        }
    }
    return (
        <>
            <SolutionLogoConst />
            <>
                <form className="console-forms user-auth-form" onSubmit={onLoginSubmit} noValidate>    
                    <div>
                        <h2 className="form-title">Login to your account</h2>
                        <Grid container spacing={2} alignItems='center'style={{display:'inline-flex'}} >
                            <Grid item md={12} container>
                                <TextField
                                    required
                                    type="email"
                                    name='email'
                                    label="Registered Email Address"
                                    placeholder='johndoe@doe.com'
                                    fullWidth
                                    margin="normal"
                                    value={userInfo.email}
                                    error={error.email ? true : false}
                                    helperText={error?.email || ''}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                    onChange={(e) => {
                                        setUserInfo({ ...userInfo, email: e.target.value });
                                        setError({ ...error, email: '' });
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon color='action' style={{fontSize:'18px'}}/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item md={12} container >
                                <TextField
                                    required
                                    type={pwdVisibility.password ? 'text' : 'password'}
                                    name='password'
                                    label='Password'
                                    placeholder='Please Enter Your password'
                                    value={userInfo?.password || ''}
                                    onChange={(e) => {
                                        setUserInfo({ ...userInfo, password: e.target.value });
                                        setError({ ...error, password: '' });
                                    }}
                                    error={error?.password}
                                    helperText={error?.password || ''}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon color='action' style={{fontSize:'18px'}}/>
                                            </InputAdornment>
                                        ),

                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <IconButton onMouseDown={(e) => setPwdVisibility({ ...pwdVisibility, password: !pwdVisibility.password })}>
                                                    {pwdVisibility.password ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className="forgot-pwd">
                                <div><a href="/forgot-password">Forgot password?</a></div>
                            </Grid>
                            <Grid item md={12} direction='column' alignItems='center' style={{padding:'0px'}}>
                                <p className='condition'>
                                    By clicking Login, you agree to our <a href='/termscondition' target='_blank'>Terms</a>, <a href='./data-policy' target='_blank'>Data Policy </a>
                                    <br />
                                    and <a href='https://www.acumen.aero/policy' target='_blank'>Privacy Policy.</a>
                                </p>
                            </Grid>
                            <Grid item md={12} container>
                                <Button style={{ bottom: '10px' }} fullWidth type='submit' color='primary' size='medium' variant='contained' >{ isLoading ? "Processing..." : "LOGIN" }</Button>
                            </Grid>
                        </Grid>
                    </div>
                    
                </form>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                    <Fade>
                        <SelectLessor userResponse={userResponse} />
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={otp}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                    <Fade>
                        <LoginAuth
                            authType={'enabled'}
                            getResponseBack={(e) => { setOpen(false); onOtpVerify(e) }}
                            userResponse={userResponse}
                        />
                    </Fade>
                </Modal>
            </>
        </>
    )
}
export default withRouter(Login);