import React from 'react';
import { Grid, Container, Button } from '@material-ui/core';
import finS from '../../../shared/assets/img/fin-s_icon.png';
import commverge from '../../../shared/assets/img/commverge_icon.png';
import downArrow from '../../../shared/assets/img/arrow_down_icon.svg';
import { isMobile } from 'react-device-detect';
import { trackFeatureActivity } from '../../../utils/mixpanel';
const OtherFeatures = ({at, scrollToDsa, otherTabIndex, scrollToNextSecAl, toggleRequestDemoModal, changeTab}) => {
  return(
    <section className="more-features-section" ref={at} id="other-features">
      <Container maxWidth="xl">
        <Grid>
        <ul className="list-inline module-nav-link">
          <li className={otherTabIndex === 0 ? "active" : "list-inline-item"} style={otherTabIndex === 0 ? { color: '#3f51b5' } : {}} onClick={() => changeTab(0, 'fin-S')}>
            <p>Asset Valuations</p>
          </li>
          <li className={otherTabIndex === 1 ? "active" : "list-inline-item"} style={otherTabIndex === 1 ? { color: '#3f51b5' } : {}} onClick={() => changeTab(1, 'commVerge')}>
            <p >Commercial Exposure</p>
          </li>
          <li className="list-inline-item digi-adv" onClick={scrollToDsa}>
            <p>Technology Consulting</p>
          </li>
        </ul>
        </Grid>
        {otherTabIndex === 0 &&
          <div className="slider-wrapper">
            <Grid container spacing={0} alignItems="center">
              <Grid item md={1}></Grid>
              <Grid item md={10}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={7} md={6} xs={12} className='mobile-align'>
                    <div>
                      <h4>ASSET VALUATIONS</h4>
                      <p>
                        Generate instant and reliable valuations of commercial aircraft with fin-S. Simulate 100s scenarios for maintenance adjustments, discounted cashflow based values and inflation-impacted future values.
                      </p>
                      <ul className="list-inline btn-cta">
                        <li className="list-inline-item">
                          <Button color="primary" className="btn-color" variant="contained" size="large" onClick={() => { toggleRequestDemoModal(); trackFeatureActivity('Item Clicked ', { page_title: 'Solutions', page_section: 'Our Digital Solutions - fin-S', item_type: 'Event', item_name: 'Request a Demo' }) }}>Request a Demo</Button>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                  <Grid item  lg={5} md={6} xs={12} style={{ textAlign: 'center' }}>
                    <img className="slider-img" src={finS} alt="Fin-s Icon" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>
        }
        {(otherTabIndex === 1 ) &&
          <div className="slider-wrapper">
            <Grid container spacing={2} alignItems="center">
              <Grid item md={1}></Grid>
              <Grid item md={10}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={7} md={6} xs={12} className='mobile-align'>
                    <div >
                      <h4>COMMERCIAL EXPOSURE</h4>
                      <p>
                        Compute, forecast and simulate commercial cost exposure for 200+ aircraft-engine combinations with commVerge. Negotiate the best reserve rates through data-driven insights on intervals, on-wing life projections and event costs.
                      </p>
                      <ul className="list-inline btn-cta">
                        <li className="list-inline-item">
                          <Button elevation={0} color="primary" className="btn-color" variant="contained" size="large" onClick={() => { toggleRequestDemoModal(); trackFeatureActivity('Item Clicked ', { page_title: 'Solutions', page_section: 'Our Digital Solutions - fin-S', item_type: 'Event', item_name: 'Request a Demo' }) }}>Request a Demo</Button>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                  <Grid item lg={5} md={6} xs={12} style={{ textAlign: 'center' }}>
                    <img className="slider-img" src={commverge} alt="commverge icon" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>
        }
        <div className="down-arrow">
          <img src={downArrow} alt="Arrow Icon" onClick={scrollToNextSecAl} />
        </div>
      </Container>
    </section>
  )
}
export default OtherFeatures;
