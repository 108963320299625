import React from 'react';
import { Grid, TextField, Container, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { trackFeatureActivity } from '../../../utils/mixpanel';
import downArrow from '../../../shared/assets/img/arrow_down_icon.svg';
const AssetLifecycle = ({scrollToNextSec, toggleRequestDemoModal, onFieldChange}) => {
  return(
    <section className="asset-lifecycle-section" id="asset-lifecycle">
      <Container maxWidth="xl">
        <div className="content-wrapper">
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <h2>Got you covered throughout Asset Lifecycle. <br /> Here's how ?</h2>
            </Grid>
            <Grid item xs={12}>
              <ul className="list-inline">
                <li className="list-inline-item"><p className="text" >I am a</p></li>
                <li className="list-inline-item">
                  <Autocomplete
                    id="combo-box-demo"
                    options={['Lessor', 'Airline', 'Consultant', 'Others']}
                    getOptionLabel={(option) => option}
                    style={{ width: 255 }}
                    onChange={(e, value) => onFieldChange('user_type', value)}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                </li>
              </ul>
            </Grid>
            <Grid item xs={12}>
              <ul className="list-inline btn-cta">
                <li className="list-inline-item">
                  <Button color="primary" className="btn-color" variant="contained" size="large" onClick={() => { toggleRequestDemoModal(); trackFeatureActivity('Item Clicked ', { page_title: 'Solutions', page_section: 'Aviation Lifecycle', item_type: 'Event', item_name: 'Request a Demo' }) }}>Request a Demo</Button>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
        <div className="down-arrow asset-lifecycle">
          <img src={downArrow} alt="Arrow Icon" onClick={scrollToNextSec} />
        </div>
      </Container>
    </section>
  )
}
export default AssetLifecycle;
