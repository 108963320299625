import React from 'react';
import { Container, Button } from '@material-ui/core';
import { trackFeatureActivity } from '../../../utils/mixpanel';
const AviationBusiness = ({toggleRequestDemoModal}) => {
  return(
    <section className="aviation-business-section">
      <Container maxWidth="xl">
        <div className="module-header">
          <h2>Put a jetpack on your aviation business with SPARTA</h2>
          <p>See how SPARTA can integrate within your business processes and maximise efficiency.</p>
          <Button color="primary" className="btn-color" variant="contained" size="medium" style={{ marginTop: '40px' }} onClick={() => { toggleRequestDemoModal(); trackFeatureActivity('Item Clicked ', { page_title: 'Solutions', page_section: 'Aviation Business', item_type: 'Event', item_name: 'Get Started' }) }}>Get Started</Button>
        </div>
      </Container>
    </section>
  )
}
export default AviationBusiness;
