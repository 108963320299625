import { castleAirInstance } from "../../shared/components"

export const getPermissionKey = (assetType) => {
    if (assetType === 'fan' || assetType === 'hpc' || assetType === 'hpt' || assetType === 'lpt' || assetType === 'engines') {
        return 'engine'
    } else {
        if (assetType === 'lg') {
            return 'landing_gears'
        } else {
            return assetType
        }

    }
}
export const offWingObj = {
    tsn_at_removal: null,
    csn_at_removal: null,
    date_of_removal: null,
    reason_for_removal: '',
    preservation_performed: false,
    release_date_of_the_engine: null,
    shop_visit_report_provided: false,
    type_of_shop_visit: '',
    expected_date_of_shipment_back_to_lessee: null,
    expected_release_date_of_the_engine: null,
    induction_date_of_engine: null,
    work_scope_status: '',
    shop_visit_name: '',
    shop_visit_location: '',
    shop_visit_requirement: '',
    expected_date_of_shipment_to_shop: null,
    selected_shop: '',
    off_wing_location: '',
}
export const onWingObj = {
    titled_aircraft: '',
    other_aircraft_msn: ''
}
export const assemblyTypeObj = {
    'ENGINE': 2,
    'Engine': 2,
    'APU': 3,
    'LG': 4,
    'PROPELLER': 5,
    'Propeller': 5
}
export const weightObj = [
    'purchase_max_zero_fuel_weight',
    'purchase_max_zero_fuel_weight_kgs',
    'purchase_basic_operating_weight',
    'purchase_basic_operating_weight_kgs',
    'purchase_max_take_off_weight',
    'purchase_max_take_off_weight_kgs',
    'purchase_max_landing_weight',
    'purchase_max_landing_weight_kgs',
    'purchase_max_taxi_weight',
    'purchase_max_taxi_weight_kgs',
    'purchase_basic_empty_weight',
    'purchase_basic_empty_weight_kgs',
    'operating_max_zero_fuel_weight',
    'operating_max_zero_fuel_weight_kgs', 
    'operating_max_take_off_weight',
    'operating_max_take_off_weight_kgs',
    'operating_max_landing_weight',
    'operating_max_landing_weight_kgs',
    'operating_max_taxi_weight',
    'operating_max_taxi_weight_kgs',
    'operating_basic_empty_weight',
    'operating_basic_empty_weight_kgs',
    'operating_basic_operating_weight',
    'operating_basic_operating_weight_kgs',
    'delivered_max_zero_fuel_weight',
    'delivered_max_zero_fuel_weight_kgs',
    'delivered_max_take_off_weight',
    'delivered_max_take_off_weight_kgs',
    'delivered_max_landing_weight',
    'delivered_max_landing_weight_kgs',
    'delivered_max_taxi_weight',
    'delivered_max_taxi_weight_kgs',
    'delivered_basic_empty_weight',
    'delivered_basic_empty_weight_kgs',
    'delivered_basic_operating_weight',
    'delivered_basic_operating_weight_kgs'
]

export const exportTypes = {
    all: {
        label: 'All Assembly',
        value: 'all'
    },
    titled: {
        label: 'Titled Assembly',
        value: 'titled'
    },
    assigned: {
        label: 'Assigned Assembly',
        value: 'assigned'
    },
    fitted: {
        label: 'Fitted Assembly',
        value: 'fitted'
    },
    custom: {
        label:'Custom',
        value:'custom'
    }
};
export  const maFilterOps ={
    serial_number:{
        'inputType':'text',
        'title':"Serial Number"
    },
    assembly_status:{
        'inputType':'dropdown',
        'title': 'Assembly Status',
        'options':[{label:'Titled MSN', value:1}, {label:'Assigned MSN', value:2},{label:'On Wing MSN', value:3}],
        'labelKey':'label',
        'valueKey':'value',
        'multiple':true
    },
    asset_type:{
        'inputType':'dropdown',
        'title': 'Asset Type',
        'options':[{label:'Engine', value:2}, {label:'APU', value:3},{label:'Landing Gear', value:4},{label:'Propeller', value:5}],
        'labelKey':'label',
        'valueKey':'value',
        'multiple':false
    }
}
export const dataroomRootPermision=(dataroom, destinationUuid, recordsPermission)=>{
    if ((dataroom?.navigation[0]?.uuid == destinationUuid[0]) && castleAirInstance ) {
          if ( recordsPermission?.records?.data_room?.includes('RFA')) {
            return true
          }
          else {
            return false
          }
        }
      else {
        return true
      }

}