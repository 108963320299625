import React, { Component } from 'react';
import { Container } from '@material-ui/core';
import { fadeIn } from 'react-animations'
import Radium, {StyleRoot} from 'radium';

const styles = {
  fadeIn: {
    animation: 'x 1.5s',
    animationName: Radium.keyframes(fadeIn, 'fadeIn')
  }
}
class AssetDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
          newTextDisplay: 0,
          activeFunc: true
        }
    }

    componentDidMount(){
      this.generateDynamicContent()
    }
    generateDynamicContent = () => {
      var newTextDisplay = this.state.newTextDisplay
      if(this.state.activeFunc === true){
        if(newTextDisplay < 3){
          newTextDisplay += 1
        }else{
          newTextDisplay = 1
        }
        this.setState({newTextDisplay, activeFunc: false})
        setTimeout(() => this.setState({activeFunc: true}, () => this.generateDynamicContent()), 5000)
      }
    }

    render() {
      const { newTextDisplay } = this.state
        return(
          <section className="asset-detail-section">
            <Container maxWidth="xl">
              <StyleRoot>
              {
                newTextDisplay === 1 ?
                <div className="asset-detail-card" style={styles.fadeIn}>
                  <span className="asset-head">Asset Acquisition </span> <span className="asset-detail">Accelerate decision making process through predictive analytics and data driven due-diligence.</span>
                </div>
                :null
              }
              {
                newTextDisplay === 2 ?
                <div className="asset-detail-card" style={styles.fadeIn}>
                  <span className="asset-head">Asset Management</span> <span className="asset-detail">Amplify control over commercial lease through enhanced data visibility.</span>
                </div>
                :null
              }
              {
                newTextDisplay === 3 ?
                <div className="asset-detail-card" style={styles.fadeIn}>
                  <span className="asset-head">Asset Transitions</span> <span className="asset-detail">Seamlessly execute transitions through multi-party data collaboration.</span>
                </div>
                :null
              }
            </StyleRoot>
            </Container>
          </section>
        )
    }
}
export default AssetDetails;
